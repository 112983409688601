import React from 'react'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'

const StyledDistributorsTable = styled.table`
  border-collapse: collapse;
  font-feature-settings: 'palt';
  line-height: 1.5;
  width: 100%;

  thead {
    display: none;
  }

  tr {
    border-top: 1px solid var(--color-figure-0100);
    display: grid;
    grid-template-columns: 5em 1fr;
    grid-template-rows: auto auto auto;
    padding: 0.75em 0;
  }

  tr:last-child {
    border-bottom: 1px solid var(--color-figure-0100);
  }

  thead tr {
    border-top: none;
  }

  th {
    text-align: left;
  }

  td.isPref {
    color: transparent;
    grid-column: 1 / 2;
    user-select: none;
    white-space: nowrap;
  }

  tr.isFirst td.isPref {
    color: inherit;
    user-select: auto;
  }

  td.isName,
  td.isAddr,
  td.isTel {
    grid-column: 2 / 3;
  }

  td.isTel small {
    color: var(--color-figure-0500);
    font-size: 0.6em;
  }

  a {
    border-bottom: 1px solid var(--color-figure-0250);
    color: inherit;
    padding-bottom: 0.2em;
    text-decoration: none;
    transition: border-bottom-color 0.25s;

    &:active {
      border-bottom-color: var(--color-figure-1000);
    }

    @media (hover: hover) {
      &:hover {
        border-bottom-color: var(--color-figure-1000);
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    thead {
      display: table-header-group;
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: 0.75em 0.5em;
      vertical-align: top;
      white-space: nowrap;
    }

    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    th:last-child,
    td:last-child {
      padding-right: 0;
    }

    td {
      border-bottom: 1px solid var(--color-figure-0100);
    }

    td.isName,
    td.isAddr {
      white-space: normal;
    }
  }
`

export const DistributorsTable: React.FC = ({ children }) => (
  <StyledDistributorsTable>
    <thead>
      <tr>
        <th>都道府県</th>
        <th>店名</th>
        <th>所在地</th>
        <th>電話番号</th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </StyledDistributorsTable>
)
