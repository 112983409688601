import React, { useEffect, useState } from 'react'
import moji from 'moji'
import parse from 'csv-parse/lib/sync'
import styled from '@emotion/styled'

import { AppPageProps } from '../types'
import { breakpoints } from '../styles/variables'
import { DistributorsTable } from '../components/DistributorsTable'
import { Layout } from '../components/Layout'

interface Item {
  pref: string
  name: string
  address: string
  tel: string
  note: string
}

const clean = (text: string) =>
  moji(text.trim())
    .convert('ZS', 'HS')
    .convert('ZE', 'HE')
    .convert('HK', 'ZK')
    .toString()

const Container = styled.div`
  padding: 4rem 2rem 8rem;

  > h1 {
    font-family: var(--font-family-display);
    font-size: 2rem;
    font-weight: normal;
    margin-bottom: 4rem;
    text-align: center;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0 auto;
    max-width: 52rem;
    padding: 10rem 8rem;
  }
`

const DistillerPage: React.FC<AppPageProps> = ({
  pathContext: { langKey },
}) => {
  const [groups, setGroups] = useState<Item[][]>([])

  useEffect(() => {
    ;(async () => {
      const res = await fetch('/distributors.csv')
      const data = await res.text()
      const list = parse(data)

      const items: Item[] = list.map(
        ([pref, name, addr1, addr2, tel, note]: string[]) => ({
          pref: clean(pref),
          name: clean(name),
          address: clean(`${addr1}${addr2}`),
          tel: clean(tel),
          note: clean(note),
        })
      )

      const itemGroups: Item[][] = items.reduce((gs, item: Item) => {
        const lastGroup = gs[gs.length - 1]
        return lastGroup && lastGroup[0].pref === item.pref
          ? [...gs.slice(0, -1), [...lastGroup, item]]
          : [...gs, [item]]
      }, [] as Item[][])

      setGroups(itemGroups)
    })()
  }, [])

  return (
    <Layout
      langKey={langKey}
      pageTitle="取扱店"
      titleSublabel="取扱店"
      titleLabel="Distributors"
      headerMode="product"
    >
      <Container>
        <h1>取扱店</h1>
        <DistributorsTable>
          {groups.map((items) =>
            items.map((item, i) => (
              <tr key={i.toString()} className={i === 0 ? 'isFirst' : ''}>
                <td className="isPref">{item.pref}</td>
                <td className="isName">{item.name}</td>
                <td className="isAddr">{item.address}</td>
                <td className="isTel">
                  {item.tel ? <a href={`tel:${item.tel}`}>{item.tel}</a> : null}
                  {item.note ? (
                    <>
                      <br />
                      <small>{item.note}</small>
                    </>
                  ) : null}
                </td>
              </tr>
            ))
          )}
        </DistributorsTable>
      </Container>
    </Layout>
  )
}

export default DistillerPage
